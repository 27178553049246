import { useContext, useEffect, useState } from "react";
import GamesContext from "../../contexts/GamesContext";
import BankContext from "../../contexts/BankContext";
import { BankListType } from "../../types/Bank";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import WebsiteSettingsContext from "../../contexts/WebsiteSetting";
import moment from "moment";

interface ProvidersType {
    provider: string,
    name: string,
    logo: string,
    sort: number | null
}

const Footer = () => {
    const { games } = useContext(GamesContext);
    const { bankSupport } = useContext(BankContext);
    const [groupedProvider, setGroupedProvider] = useState<ProvidersType[]>([]);
    const { websiteSetting } = useContext(WebsiteSettingsContext);
    
    useEffect(() => {
        if(!games)
        {
            return;
        }

        const filtered = games;
        const data: ProvidersType[] = [];
        filtered.reduce((acc, item) => {
            if(data.findIndex((element) => element.provider === item.provider) === -1)
            {
                data.push({
                    provider: `${item.provider}`,
                    name: `${item.provider_name}`,
                    logo: `${item.provider_logo}`,
                    sort: item.provider_sort ? item.provider_sort : null
                })
            }

            return acc;
        }, {});
        setGroupedProvider(data);
    }, [games]);

    return (
        <>
            <div className="w-full bg-white mt-20 p-3 shadow-[0_-4px_6px_rgba(0,0,0,0.1)]">
                <div className="container max-w-[800px] mx-auto">
                    <p className="flex justify-center text-sm mb-2 border-b border-blue-500">
                        Providers
                    </p>
                    <div className="flex flex-wrap justify-center gap-1">
                        {
                            groupedProvider
                            .sort((a, b) => {
                                if(a.sort === null) return 1;
                                if(b.sort === null) return 0;
                                return a.sort - b.sort;
                            })
                            .map((provider: ProvidersType, index: number) => (
                                <LazyLoadImage
                                    key={`index-provider-${index}-${provider.provider.toLowerCase()}`}
                                    src={provider.logo}
                                    alt={provider.name}
                                    className="w-[100%] max-w-[30px] max-h-[30px] rounded-lg transition-all duration-300 hover:scale-110 grayscale-[70%] hover:grayscale-[0%]"
                                    wrapperClassName="transition-all duration-300"
                                    effect="blur"
                                />
                            ))
                        }
                    </div>
                    <p className="flex justify-center text-sm my-2 border-b border-blue-500">
                        Accept payments
                    </p>
                    <div className="flex flex-wrap justify-center gap-1">
                        {
                            bankSupport.map((bank: BankListType, index: number) => (
                                <LazyLoadImage
                                    key={`index-bank-${index}-${bank.bank_abbr.toLowerCase()}`}
                                    src={bank.bank_logo}
                                    alt={bank.bank_name_en}
                                    className="w-[100%] max-w-[30px] max-h-[30px] rounded-lg transition-all duration-300 hover:scale-110 grayscale-[70%] hover:grayscale-[0%]"
                                    wrapperClassName="transition-all duration-300"
                                    effect="blur"
                                />
                            ))
                        }
                    </div>
                    <p className="flex justify-between text-sm my-2 border-t border-blue-500">
                        <p className="text-xs">
                            SUPPORT: FACEBOOK | LINE | INSTAGRAM
                        </p>
                        <p className="text-xs">
                            &copy;&nbsp;2025{ moment().format("YYYY") !== "2025" && ` - ${moment().format("YYYY")}` }&nbsp;{websiteSetting?.name.toUpperCase()}&nbsp;Copyright
                        </p>
                    </p>
                </div>
            </div>
        </>
    )
}

export default Footer;