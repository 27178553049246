import { useContext } from "react";
import MenuIcon from "./../../assets/images/icon_menu.png";
import LoginContext from "../../contexts/LoginContext";
import AuthContext from "../../contexts/AuthContext";
import Marquee from "react-fast-marquee";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import SidebarContext from "../../contexts/SidebarContext";
import { useTranslation } from "react-i18next";

const Header = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const navigate = useNavigate();
    const { isOpen, setIsOpen } = useContext(SidebarContext);
    const { accessToken, memberData, logout } = useContext(AuthContext);
    const { setLoginModalIsOpen, setActiveTab } = useContext(LoginContext);
    
    const openLoginModal = (tab: string) => {
        setActiveTab(tab);
        setLoginModalIsOpen(true);
    }

    return (
        <>
            <div className="w-full bg-gray-200 shadow-md fixed top-0 left-0 z-50 pl-2 py-1">
                <div className="flex flex-cols gap-1">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                        </svg>
                    </div>
                    <div className="flex items-center">
                        <div className="w-full text-gray-800 text-sm">
                            <Marquee
                                pauseOnHover={true}
                            >
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                                สล๊อต คาสิโน ยิงปลา หวย ครบจบที่เดียว ส่งตรงจากสิงคโปร์ ฝาก-ถอน ระบบออโต้ 3 วิเท่านั้น&nbsp;
                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white shadow-md fixed top-7 left-0 z-50">
                <div className="container max-w-[1100px] mx-auto">
                    <div className="flex justify-between p-3 gap-3">
                        <div className="flex items-center gap-2">
                            <div className="cursor-pointer" onClick={() => setIsOpen(true)}>
                                <LazyLoadImage src={MenuIcon}  style={{ maxWidth: '25px' }} effect="blur" />
                            </div>
                            <div className="text-sm cursor-pointer" onClick={() => navigate('/')}>
                                <img src={`${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/logo-horizontal.png?v=${Math.floor(Date.now() / 1000)}`} alt="Logo" style={{ 
                                    maxWidth: '150px'
                                }} />
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            {
                                !accessToken ? <>
                                    <div>
                                        <button
                                            className="bg-white px-5 py-1 rounded hover:bg-blue-500 hover:text-white focus:outline-none transition duration-300 ease-in-out border-2 border-blue-300 hover:border-blue-500 text-sm"
                                            onClick={() => openLoginModal('login')}
                                        >
                                            {t('Login')}
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            className="bg-blue-500 text-white py-1 px-5 rounded hover:bg-blue-600 focus:outline-none transition duration-300 ease-in-out border-2 border-blue-500 hover:border-blue-600 text-sm"
                                            onClick={() => openLoginModal('register')}
                                        >
                                            {t('Register')}
                                        </button>
                                    </div>
                                </> : <>
                                    <div className="flex flex-col items-end gap-1">
                                        <p className="text-sm leading-none">
                                            {t('Username')}: {
                                                (memberData && memberData?.username_application) ? 
                                                    `${memberData?.username_application}`
                                                :
                                                    <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                                            }
                                        </p>
                                        <p className="text-sm leading-none">
                                            {t("Credit")}: {
                                                (memberData && memberData?.credit_data) ? 
                                                    parseFloat(`${memberData?.credit_data.availableCredit}`).toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })
                                                :
                                                    <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                                            }
                                        </p>
                                    </div>
                                    <div>
                                        <button
                                            className="bg-white text-red py-1 px-5 rounded hover:bg-red-600 focus:outline-none transition duration-300 ease-in-out border-2 border-red-500 hover:border-red-600 text-sm hover:text-white"
                                            onClick={logout}
                                        >
                                            {t('Logout')}
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                marginBottom: '6.15rem'
            }}></div>
        </>
    )
}

export default Header;