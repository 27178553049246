import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/api.service';
import { BankListType } from '../types/Bank';

export interface BankContextProp {
    bankSupport: BankListType[],
    setBankSupport: (banks: BankListType[]) => void
}

const BankContext = createContext<BankContextProp>({} as BankContextProp);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const BankContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [bankSupport, setBankSupport] = useState<BankListType[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const service = new ApiService();
                const requestGetBankSupport = await service.getBankSupport();
                if(requestGetBankSupport.data.status_code === 200)
                {
                    setBankSupport(requestGetBankSupport.data.lists);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    const value = useMemo(
        () => ({
            bankSupport,
            setBankSupport
        }),
        [bankSupport]
    );
    return <BankContext.Provider value={value}>{children}</BankContext.Provider>;
};

export default BankContext;