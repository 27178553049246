import { FC, useEffect } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: any;
  children?: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isOpen && onClose) {
                onClose();
            }
        };

        // ปิดการ scroll เมื่อ modal เปิด
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.body.style.overflow = ""; // คืนค่า scroll เมื่อ modal ปิด
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-96 p-6 w-[100%] h-[100%] sm:w-[100%] sm:h-[100%] md:w-auto md:h-auto md:min-w-[450px] lg:w-auto lg:h-auto lg:min-w-[600px]">
                <div className="flex justify-between">
                    {title ? (
                        <div>
                            <h2 className="text-xl font-semibold">{title}</h2>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="cursor-pointer" onClick={onClose ? onClose : () => {}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <hr style={{ padding: 0 }} />
                <div className="mt-2 text-gray-600">{children}</div>
            </div>
        </div>
    );
};

export default Modal;