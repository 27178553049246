import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { ApiService } from "../../../services/api.service";
import moment from "moment";
import { TransactionsType } from "../../../types/Transactions";
import Pagination from "../../_utils/Pagination";
import { useTranslation } from "react-i18next";

const WithdrawTransactions = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { accessToken } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<TransactionsType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limitPerPage, setLimitPerPage] = useState<number>(10);

    useEffect(() => {
        getWithdrawTransactions();
    }, [accessToken]);

    const getWithdrawTransactions = async () => {
        if(!accessToken || loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetWithdrawTransactions = await service.getTransactions(
                accessToken,
                1,
                100,
                moment().subtract(30, 'days').format("YYYY-MM-DDTHH:mm:ss"),
                moment().format("YYYY-MM-DDTHH:mm:ss"),
                "withdraw"
            );

            if(requestGetWithdrawTransactions.data.status_code === 200)
            {
                setTransactions(requestGetWithdrawTransactions.data.transactions);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="overflow-hidden overflow-x-auto rounded-lg border border-gray-300">
                <table className="min-w-full text-left text-sm">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">
                                <p className="flex justify-center">
                                    {t('Amount')}
                                </p>
                            </th>
                            <th className="px-4 py-2">
                                <p className="flex justify-center">
                                    {t('Channels')}
                                </p>
                            </th>
                            <th className="px-4 py-2">
                                <p className="flex justify-center">
                                    {t('Status')}
                                </p>
                            </th>
                            <th className="px-4 py-2">
                                <p className="flex justify-center">
                                    {t('Updated_at')}
                                </p>
                            </th>
                            <th className="px-4 py-2">
                                <p className="flex justify-center">
                                    {t('Created_at')}
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-300">
                        {
                            !loading ? transactions
                            .slice((currentPage - 1) * limitPerPage, ((currentPage - 1) * limitPerPage) + limitPerPage)
                            .map((transaction: TransactionsType, index: number) => (
                                <tr className="hover:bg-gray-50">
                                    <td className="px-4 py-2">
                                        <p className="flex justify-center">
                                            {
                                                parseFloat(`${transaction.amount}`).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                            }
                                        </p>
                                    </td>
                                    <td className="px-4 py-2">
                                        <p className="flex justify-center">{transaction.channel_name}</p>
                                    </td>
                                    <td
                                        className={`px-4 py-2 ${transaction.status === 'success' ? "text-green-600" : (
                                            transaction.status === 'pending' ? "text-yellow-600" : "text-red-600"
                                        )}`}
                                    >
                                        <p className="flex justify-center">{transaction.status.toUpperCase()}</p>
                                    </td>
                                    <td className="px-4 py-2">
                                        <p className="flex justify-center">
                                            {
                                                moment(transaction.updatedAt).format("DD/MM/YYYY HH:mm")
                                            }
                                        </p>
                                    </td>
                                    <td className="px-4 py-2">
                                        <p className="flex justify-center">
                                            {
                                                moment(transaction.txn_createdAt).format("DD/MM/YYYY HH:mm")
                                            }
                                        </p>
                                    </td>
                                </tr>
                            )) : <tr className="hover:bg-gray-50">
                                <td className="px-4 py-2" colSpan={5}>
                                    <p className="flex justify-center">
                                        {t('Loading')}...
                                    </p>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="my-3 flex justify-center">
                    <Pagination
                        onPageChange={setCurrentPage}
                        currentPage={currentPage}
                        totalPages={
                            Math.max(1, Math.ceil(transactions.length / limitPerPage))
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default WithdrawTransactions;