import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { ApiService } from "../../../services/api.service";
import { CategoryType, ProviderType } from "../../../types/Games";
import HomeGame from "./HomeGame";
import GamesContext from "../../../contexts/GamesContext";
import WebsiteSettingsContext from "../../../contexts/WebsiteSetting";
import Button from "../../_tailwild/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeOverAllGames = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const navigate = useNavigate();
    const { websiteSetting } = useContext(WebsiteSettingsContext);
    const { categories } = useContext(GamesContext);
    
    return (
        <div className="grid grid-cols-12 gap-3">
            {
                (websiteSetting && websiteSetting.main_page_category_show) && categories.map((category: CategoryType, index: number) => {
                    return (
                        (websiteSetting.main_page_category_show[category.name.toLowerCase()]) ? <div className="col-span-12" key={`home-category-${index}`}>
                            <div className="flex justify-between">
                                <p className="text-lg">
                                    {t(category.name)}
                                </p>
                                <Button
                                    className="px-2 py-0 text-sm"
                                    onClick={() => navigate(`/game/${category.name.toLowerCase()}`)}
                                >
                                    {t("ViewAll")}
                                </Button>
                            </div>
                            <hr />
                            <HomeGame category_name={category.name} />
                        </div> : null
                    )
                })
            }
        </div>
    )
}

export default HomeOverAllGames;