import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";

export class ApiService {
    private baseURL: string | undefined;
    private instance: AxiosInstance;

    constructor(timeout?: number | undefined | null) {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: timeout || 120 * 1000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = (): string | undefined => {
        return this.baseURL;
    }

    get = async (path: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.get(path, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    post = async (path: string, body: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
        return this.instance.post(path, body, config).then((res: AxiosResponse) => {
            return res;
        });
    }

    checkAccessToken = async (access_token: string): Promise<AxiosResponse> => {
        return this.post('/member/checkaccesstoken', {}, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    login = async (username: string, password: string, website_id: string | number): Promise<AxiosResponse> => {
        return this.post(`/member/login`, {
            phone: username,
            password,
            website_id
        }, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    register = async (phone: string, password: string, first_name: string, last_name: string, bank_abbr: string, bank_acc_number: string, website_id: string | number): Promise<AxiosResponse> => {
        return this.post(`/member/register`, {
            phone,
            password,
            first_name,
            last_name,
            bank_abbr,
            bank_acc_number,
            website_id
        }, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getWebsiteSettings = async (): Promise<AxiosResponse> => {
        return this.get(`/settings/${process.env.REACT_APP_WEBSITE_ID}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getGamesAndCategories = async (): Promise<AxiosResponse> => {
        return this.get(`/games/get/${process.env.REACT_APP_WEBSITE_ID}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getCategory = async (): Promise<AxiosResponse> => {
        return this.get(`/games/${process.env.REACT_APP_WEBSITE_ID}/category`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getProviderByCategory = async (category: string): Promise<AxiosResponse> => {
        return this.get(`/games/${process.env.REACT_APP_WEBSITE_ID}/provider/${category}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }
    
    getGamesByCategoryAndProvider = async (category: string, provider: string): Promise<AxiosResponse> => {
        return this.get(`/games/get/${process.env.REACT_APP_WEBSITE_ID}/${category}/${provider}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getRedirectGameUrl = async (access_token: string, game_id: string, provider: string, tab: string): Promise<AxiosResponse> => {
        return this.post(`/member/get/startgameurl`, {
            game_id,
            provider,
            tab
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getTransactions = async (access_token: string, page: string | number, limit: string | number, from: string, to: string, txn_type: string): Promise<AxiosResponse> => {
        return this.get(`/member/get/history/transaction?page=${page}&limit=${limit}&from=${from}&to=${to}&txn_type=${txn_type}`, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getBankSupport = async (): Promise<AxiosResponse> => {
        return this.get(`/bank/support`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPaymentDepositChannel = async (): Promise<AxiosResponse> => {
        return this.get(`/payment/get/payin/${process.env.REACT_APP_WEBSITE_ID}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestCreatePayin = async (access_token: string, payment_id: number, amount: string): Promise<AxiosResponse> => {
        return this.post(`/payment/${payment_id}/create/payin`, {
            amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    requestCreatePayout = async (access_token: string, payment_id: number, amount: string): Promise<AxiosResponse> => {
        return this.post(`/payment/${payment_id}/create/payout`, {
            amount
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPromotions = async (): Promise<AxiosResponse> => {
        return this.get(`/promotion/${process.env.REACT_APP_WEBSITE_ID}`, {}).then((res: AxiosResponse) => {
            return res;
        });
    }

    getPromotion = async (access_token: string, promotion_id: string | number): Promise<AxiosResponse> => {
        return this.post(`/member/get/promotion`, {
            promotion_id
        }, {
            headers: {
                Authorization: access_token
            }
        }).then((res: AxiosResponse) => {
            return res;
        });
    }
}