import { useContext, useEffect, useState } from "react";
import { ApiService } from "../../../services/api.service";
import { ListPromotionsType } from "../../../types/Promotions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Modal from "../../_utils/Modal";
import DangerouslySetInnerHTML from "../../_utils/DangerouslySetInnerHTML";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../_tailwild/Button";
import showNotification from "../../_utils/showNotification";
import { useTranslation } from "react-i18next";

const Promotions = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { accessToken } = useContext(AuthContext);
    const [promotions, setPromotions] = useState<ListPromotionsType[]>([]);

    const [selectedPromotion, setSelectedPromotion] = useState<ListPromotionsType | null>(null);
    const [showPromotion, setShowPromotion] = useState<boolean>(false);

    useEffect(() => {
        getPromotions();
    }, []);

    useEffect(() => {
        if(!selectedPromotion)
        {
            setSelectedPromotion(null);
        }
    }, [showPromotion])

    const getPromotions = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetPromotions = await service.getPromotions();

            if(requestGetPromotions.data.status_code === 200)
            {
                setPromotions(requestGetPromotions.data.promotions);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const getPromotion = async (promotion_id: number) => {
        if(!accessToken || loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const requestGetPromotion = await service.getPromotion(accessToken, promotion_id);
            const responseCode = requestGetPromotion.data.status_code;
            const isSuccess = requestGetPromotion.data.status_code === 200;
            
            setShowPromotion(false);
            showNotification(
                t('Notification'),
                isSuccess ? t("Get_promotion_successfully")
                : `Get promotion failed (${responseCode} - ${requestGetPromotion.data.message})`,
                isSuccess ? "success" : "danger",
                isSuccess ? 3000 : 5000
            )
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="grid grid-cols-12 gap-3 items-center">
            <Modal
                isOpen={showPromotion}
                onClose={() => setShowPromotion(false)}
                title={selectedPromotion ? `${selectedPromotion.name}` : null}
            >
                <div className="my-3"></div>
                {
                    selectedPromotion ? <div className="container max-w-[1100px] mx-auto">
                        <div className="grid grid-cols-12 gap-2">
                            <div className={`col-span-12 md:col-span-3 lg:col-span-4`}>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/cdn/${selectedPromotion.poster_image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={selectedPromotion.name}
                                    className="w-full h-full object-cover rounded-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-lg"
                                />
                            </div>
                            <div className={`col-span-12 md:col-span-9 lg:col-span-8`}>
                                <p className="text-lg text-blue-800">{t("Details")}: </p>
                                <DangerouslySetInnerHTML
                                    htmlContent={selectedPromotion.html}
                                />
                                <hr className="my-3" />
                                <p className="text-md text-blue-800">{t("Playable_categories")}: </p>
                                {
                                    Object.entries(JSON.parse(selectedPromotion.list_turnover))
                                    .filter(([_, value]) => value)
                                    .map(([key], index) => (
                                        <p className="text-sm">
                                            {index+1}.{key.toUpperCase()}&nbsp;
                                        </p>
                                    ))
                                }
                                
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <hr/>
                            </div>
                            <div className={`col-span-12 lg:col-span-12`}>
                                <Button className="w-full" onClick={() => getPromotion(selectedPromotion.id)}>
                                    {t("Get_promotion")}
                                </Button>
                            </div>
                        </div>
                    </div> : null
                }
            </Modal>
            {
                loading ? <div className="col-span-12 lg:col-span-12">
                    <div className="flex justify-center">
                        <span className="inline-flex h-[10px] w-[10px] animate-ping rounded-full bg-sky-400 opacity-75"></span>
                    </div>
                </div> : promotions.map((promotion: ListPromotionsType, index: number) => (
                    <div
                        className="col-span-12 lg:col-span-6 rounded-lg shadow-md hover:scale-105 transition-all duration-300 cursor-pointer"
                        key={`promotion-key-${index}`}
                        onClick={() => {
                            setSelectedPromotion(promotion);
                            setShowPromotion(true);
                        }}
                    >
                        <div className="flex flex-col">
                            <div>
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_API_URL}/cdn/${promotion.poster_image}?v=${Math.floor(Date.now() / 1000)}`}
                                    alt={promotion.name}
                                    className="w-full h-full object-cover rounded-t-lg"
                                    wrapperClassName="w-full h-full object-cover rounded-t-lg"
                                />
                            </div>
                            <div className="bg-white w-full">
                                <p className="text-center whitespace-nowrap overflow-hidden text-ellipsis">
                                    {promotion.name}
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Promotions;