import { useFormik } from "formik";
import { useContext, useState } from "react";
import { ApiService } from "../../services/api.service";
import Button from "../_tailwild/Button";
import AuthContext from "../../contexts/AuthContext";
import showNotification from "../_utils/showNotification";
import { DepositChannelType } from "../../types/Payment";
import { useTranslation } from "react-i18next";
interface PropsType {
    payment_data: DepositChannelType | null,
    payment_id: number | null,
    is_node: boolean
}

const DepositAmount = ({ payment_data, payment_id, is_node }: PropsType) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { accessToken } = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            amount: ''
        },
        validate: (values) => {
            const errors: {
                amount?: string;
            } = {};
			if (!values.amount) {
				errors.amount = t("Required");
			}

            return errors;
        },
        onSubmit: async (values) => {
            if(loading || !accessToken || !payment_id)
            {
                return;
            }

            setLoading(true);
            try {
                const service = new ApiService();
                const requestCreatePayin = await service.requestCreatePayin(accessToken, payment_id, values.amount);
                const responseCode = requestCreatePayin.data.status_code;

                if(responseCode === 200 && requestCreatePayin.data.url)
                {
                    const userAgent = navigator.userAgent.toLowerCase();
                    const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

                    if(safari)
                    {
                        window.location.href = requestCreatePayin.data.url;
                    }
                    else
                    {
                        const link = document.createElement('a');
                        link.href = requestCreatePayin.data.url;
                        link.target = '_blank';
                        link.click();
                    }
                }

                showNotification(
                    t('Notification'),
                    responseCode === 200 ? t('Request_create_deposit_success') : `Request create deposit failed (${responseCode} - ${requestCreatePayin.data.message})`,
                    responseCode === 200 ? "success" : "danger",
                    responseCode === 200 ? 3000 : 5000
                );
            } catch (error) {
                console.error('Request create payin failed', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <>
            {
                !payment_id ? "Please select channel" : (
                    is_node ? <div className="grid grid-cols-12 gap-2 items-center">
                        {
                            payment_data && <>
                                <div className="col-span-12 lg:col-span-12">
                                    <label htmlFor='bank_acc_name' className="block text-sm font-medium text-gray-700">
                                        {t("Account_name")}: 
                                    </label>
                                    <input 
                                        id="bank_acc_name"
                                        name="bank_acc_name"
                                        className={`block w-full px-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-100`}
                                        value={payment_data.bank_acc_name || ""}
                                        placeholder={t("Bank_account_name")}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-12">
                                    <label htmlFor='bank_acc_number' className="block text-sm font-medium text-gray-700">
                                        {t("Account_number")}: 
                                    </label>
                                    <input 
                                        id="bank_acc_number"
                                        name="bank_acc_number"
                                        className={`block w-full px-2 border border-gray-300 rounded-md focus:ring focus:ring-indigo-100`}
                                        value={payment_data.bank_acc_number || ""}
                                        placeholder={t("Bank_account_number")}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-span-12 lg:col-span-12">
                                    <Button
                                        onClick={() => {
                                            navigator.clipboard.writeText(payment_data.bank_acc_number || "");
                                            showNotification(
                                                "Notification",
                                                "Copied to clipboard",
                                                "success",
                                                3000
                                            )
                                        }}
                                        className="block w-full text-sm"
                                    >
                                        {t("Copy_account_number")}
                                    </Button>
                                </div>
                            </>
                        }
                    </div> : <div className="grid grid-cols-12 gap-2 items-center">
                        <form className="col-span-12 lg:col-span-12" onSubmit={formik.handleSubmit}>
                            <div className="flex items-center gap-1 w-full">
                                <label htmlFor='amount' className="block text-sm font-medium text-gray-700">
                                    {t("Amount")}: 
                                </label>
                                <input 
                                    id="amount"
                                    name="amount"
                                    className={`block w-full px-2 border ${formik.errors.amount ? "border-red-500" : "border-gray-300"} rounded-md focus:ring focus:ring-indigo-100`}
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t("Amount")}
                                />
                            </div>
                        </form>
                        {/* <div className="col-span-12 lg:col-span-6">
                            {
                                formik.errors.amount && formik.touched.amount && (
                                    <div className="text-red-500 text-sm">{formik.errors.amount}</div>
                                )
                            }
                        </div> */}
                        <div className="col-span-12 lg:col-span-12">
                            <hr/>
                        </div>
                        {
                            [20, 50, 100, 150, 300, 500, 1000, 2500, 5000].map((item: number, index: number) => (
                                <div className="col-span-4 lg:col-span-4" key={`auto-fill-${item}-${index}`}>
                                    <button
                                        className="block w-full px-2 py-1 text-sm border rounded-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50 button-sm"
                                        onClick={() => formik.setFieldValue("amount", `${item}`)}
                                    >
                                        {
                                            parseFloat(`${item}`).toLocaleString('en-US', {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })
                                        }
                                    </button>
                                </div>
                            ))
                        }
                        <div className="col-span-12 lg:col-span-12">
                            <Button
                                onClick={formik.handleSubmit}
                                className="block w-full"
                            >
                                {t('Submit')}
                            </Button>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default DepositAmount;