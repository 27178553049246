import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { DepositChannelType } from "../../types/Payment";
import { ApiService } from "../../services/api.service";
import DepositAmount from "./DepositAmount";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";

interface PropsType {
    isInMemberData?: boolean
}

const DepositMain = ({ isInMemberData }: PropsType) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { accessToken } = useContext(AuthContext);
    const [gettingPayment, setGettingPayment] = useState<boolean>(false);
    const [channels, setChannels] = useState<DepositChannelType[]>([]);
    const [showAllChannels, setShowAllChannels] = useState<boolean>(false);
    const [selectedChannelId, setSelectedChannelId] = useState<number | null>(null);

    useEffect(() => {
        getPaymentChannels();
    }, [accessToken]);

    useEffect(() => {
        if(channels.length > 0)
        {
            setSelectedChannelId(channels[0].id)
        }
        else
        {
            setSelectedChannelId(null);
        }
    }, [channels]);

    const getPaymentChannels = async () => {
        if(gettingPayment)
        {
            return;
        }

        setGettingPayment(true);
        try {
            const service = new ApiService();
            const requestGetChannels = await service.getPaymentDepositChannel();

            if(requestGetChannels.data.status_code === 200)
            {
                setChannels(requestGetChannels.data.payments);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setGettingPayment(false);
        }
    }

    return (
        <div className={`container max-w-[1100px] mx-auto ${!isInMemberData && "pb-3 px-3"}`}>
            <div className={`bg-white rounded-lg p-3 ${!isInMemberData && "shadow-sm"}`}>
                <div className="grid grid-cols-12 gap-2">
                    <div
                        className={`col-span-12 lg:col-span-6 text-black hover:text-black`}
                    >
                        <div className="grid grid-cols-12 gap-2">
                            <div
                                className={`col-span-12 lg:col-span-${isInMemberData ? "4" : "2"} text-black hover:text-black`}
                            >
                                <div className="flex p-1 gap-1 justify-center lg:text-sm border-b-2 border-blue-500">
                                    {t("Channels")}
                                </div>
                            </div>
                            {
                                channels
                                    .sort((a, b) => {
                                        if(a.sort === null) return 1;
                                        if(b.sort === null) return 0;
                                        return a.sort - b.sort;
                                    })
                                    .slice(0, showAllChannels ? channels.length : 5)
                                    .map((deposit: DepositChannelType, index: number) => (
                                    <div
                                        className={`col-span-6 lg:col-span-${isInMemberData ? "4" : "2"} cursor-pointer ${selectedChannelId !== deposit.id ? "text-gray-700" : "text-black"} hover:text-black transition duration-300 ease-in-out`}
                                        key={`${index}-channel-${deposit.id}`}
                                        onClick={() => {
                                            setSelectedChannelId(deposit.id)
                                        }}
                                    >
                                        <div className={`flex bg-gray-200 p-1 rounded-lg gap-1 ${selectedChannelId === deposit.id && "shadow-inner border border-blue-300"} transition duration-300 ease-in-out`}>
                                            <LazyLoadImage
                                                src={deposit.logo}
                                                alt={deposit.name.replace(/ /g, "_").toLowerCase()}
                                                className="w-[100%] max-w-[20px] max-h-[20px] rounded-lg"
                                                effect="blur"
                                            />
                                            <p className={`text-sm whitespace-nowrap overflow-hidden text-ellipsis`}>
                                                {
                                                    deposit.name
                                                }
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                channels.length > 5 && <div
                                    className={`col-span-12 lg:col-span-12`}
                                >
                                    <div className="flex justify-center">
                                        <p className="text-sm cursor-pointer text-blue-500 hover:text-blue-900 transition duration-300 ease-in-out" onClick={() => {
                                            setShowAllChannels(!showAllChannels)
                                        }}>
                                            {
                                                !showAllChannels ? "SHOW ALL CHANNELS" : "LESS THAN CHANNELS"
                                            }
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`col-span-12 lg:col-span-6 text-black hover:text-black`}>
                        <DepositAmount
                            payment_data={selectedChannelId ? channels[channels.findIndex((channel) => channel.id === selectedChannelId)] : null}
                            payment_id={selectedChannelId}
                            is_node={channels.findIndex(channel => channel.id === selectedChannelId) !== -1 ? (channels[channels.findIndex(channel => channel.id === selectedChannelId)].is_node === 1 ? true : false) : false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepositMain;