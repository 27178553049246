import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/api.service';
import { CategoryType, GameType } from '../types/Games';
import { WebsiteSettingsType } from '../types/Settings';

export interface WebsiteSettingsContextProp {
    websiteSetting: WebsiteSettingsType | null,
}

const WebsiteSettingsContext = createContext<WebsiteSettingsContextProp>({} as WebsiteSettingsContextProp);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const WebsiteSettingsContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
    const [websiteSetting, setWebsiteSetting] = useState<WebsiteSettingsType | null>(null);

    useEffect(() => {
        (async () => {
            await getWebsiteSettings();
        })();
    }, []);

    useEffect(() => {
        if(websiteSetting)
        {
            updateMetaTags(websiteSetting);
        }
    }, [websiteSetting]);

    const updateMetaTags = (settings: WebsiteSettingsType) => {
        document.title = settings.title;
        const timestamp = Math.floor(Date.now() / 1000);
        const metaTags = [
            { name: "description", content: settings.description },
            { property: "og:url", content: process.env.REACT_APP_BASE_URL },
            { property: "og:site_name", content: settings.name },
            { property: "og:title", content: settings.title },
            { property: "og:description", content: settings.description },
            { property: "og:type", content: "website" },
            { property: "og:locale", content: "en_US" },
            { property: "og:image", content: `${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/logo192.png?v=${timestamp}` },
        ];

        metaTags.forEach(({ name, property, content }) => {
            if (!content) return;

            let element = name
                ? document.querySelector(`meta[name="${name}"]`)
                : document.querySelector(`meta[property="${property}"]`);

            if (!element) {
                element = document.createElement("meta");
                if (name) element.setAttribute("name", name);
                if (property) element.setAttribute("property", property);
                document.head.appendChild(element);
            }

            element.setAttribute("content", content);
        });

        const linkTags = [
            { rel: "icon", href: `${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/favicon.ico?v=${timestamp}` },
            { rel: "apple-touch-icon", href: `${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/logo192.png?v=${timestamp}` },
            { rel: "icon", type: "image/png", sizes: "32x32", href: `${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/favicon-32x32.png?v=${timestamp}` },
            { rel: "icon", type: "image/png", sizes: "16x16", href: `${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/favicon-16x16.png?v=${timestamp}` },
        ];

        linkTags.forEach(({ rel, type, sizes, href }) => {
            if (!href) return;

            let element = document.querySelector(`link[rel="${rel}"][sizes="${sizes}"]`) ||
                          document.querySelector(`link[rel="${rel}"]`);

            if (!element) {
                element = document.createElement("link");
                element.setAttribute("rel", rel);
                if (type) element.setAttribute("type", type);
                if (sizes) element.setAttribute("sizes", sizes);
                document.head.appendChild(element);
            }

            element.setAttribute("href", href);
        });
    };

    const getWebsiteSettings = async () => {
        setIsLoadingSettings(true);
        try {
            const service = new ApiService();
            const requestGetData = await service.getWebsiteSettings();

            if(requestGetData.data.status_code === 200)
            {
                setWebsiteSetting(requestGetData.data.settings);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoadingSettings(false);
        }
    }

    const value = useMemo(
        () => ({
            websiteSetting,
            isLoadingSettings
        }),
        [websiteSetting, isLoadingSettings]
    );
    return <WebsiteSettingsContext.Provider value={value}>{children}</WebsiteSettingsContext.Provider>;
};

export default WebsiteSettingsContext;