import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

export interface SidebarContextProp {
    isOpen: boolean,
    setIsOpen: (state: boolean) => void
}

const SidebarContext = createContext<SidebarContextProp>({} as SidebarContextProp);
interface IAuthContextProviderProps {
    children: ReactNode;
}

export const SidebarContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const value = useMemo(
        () => ({
            isOpen,
            setIsOpen
        }),
        [isOpen]
    );
    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export default SidebarContext;