import React from "react";
import Button from "../_tailwild/Button";

type PaginationProps = {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
            const pages = [];
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);
            
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
    };

    return (
        <div className="flex items-center space-x-2 mt-4">
            <button
                className="px-2 py-1 text-sm border rounded-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)}
            >
                Prev
            </button>
            {
                getPageNumbers().map((page) => (
                    <button
                        key={page}
                        className={`px-2 py-1 text-sm border rounded-md hover:bg-gray-300 ${
                            currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200"
                        }`}
                        onClick={() => onPageChange(page)}
                    >
                    {page}
                    </button>
                ))
            }
            <button
                className="px-2 py-1 text-sm border rounded-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50 button-sm"
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(currentPage + 1)}
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;