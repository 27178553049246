import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import GamesContext from "../../contexts/GamesContext";
import { GameType } from "../../types/Games";
import Button from "../_tailwild/Button";
import AuthContext from "../../contexts/AuthContext";
import LoginContext from "../../contexts/LoginContext";
import Pagination from "../_utils/Pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";

interface ProvidersType {
    provider: string,
    name: string,
    logo: string,
    sort: number | null
}

const GamesHome = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
    const { category } = useParams();
    const { accessToken } = useContext(AuthContext);
    const { games } = useContext(GamesContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [groupedProvider, setGroupedProvider] = useState<ProvidersType[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
    const { setActiveTab, setLoginModalIsOpen } = useContext(LoginContext);
    const [gettingStartGameUrl, setGettingStartGameUrl] = useState<boolean>(false);
    const [showAllProviders, setShowAllProviders] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limitPerPage, setLimitPerPage] = useState<number>(24);

    useEffect(() => {
        if(!category || !games)
        {
            return;
        }

        setSelectedProvider(null);
        const filtered = games.filter((game: GameType) => game.gameCategory.toLowerCase() === category);
        const data: ProvidersType[] = [];
        filtered.reduce((acc, item) => {
            if(data.findIndex((element) => element.provider === item.provider) === -1)
            {
                data.push({
                    provider: `${item.provider}`,
                    name: `${item.provider_name}`,
                    logo: `${item.provider_logo}`,
                    sort: item.provider_sort ? item.provider_sort : null
                })
            }

            return acc;
        }, {});
        setGroupedProvider(data);
    }, [category, games]);

    useEffect(() => {
        if(groupedProvider.length <= 0)
        {
            return;
        }

        setSelectedProvider(groupedProvider[0].provider);
    }, [groupedProvider]);

    const startGame = async (game_id: string, provider: string, tab: string) => {
        if(!accessToken)
        {
            setActiveTab('login');
            return setLoginModalIsOpen(true);
        }

        if(gettingStartGameUrl)
        {
            return;
        }

        setGettingStartGameUrl(true);
        try {
            const service = new ApiService();
            let requestGetRedirect = await service.getRedirectGameUrl(accessToken, game_id, provider, tab);

            if(requestGetRedirect.data.status_code !== 200)
            {
                // requestGetRedirect = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = requestGetRedirect.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = requestGetRedirect.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setGettingStartGameUrl(false);
        }
    }
    // const getGames = () => {
    //     if(loading)
    //     {
    //         return;
    //     }

    //     setLoading(true);
    //     try {
    //         const service = new ApiService();
    //     } catch (err) {
    //         console.error(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    
    return (
        <div className="containe max-w-[1100px] mx-auto pb-3 px-3">
            <div className="bg-white rounded-lg p-3 shadow-sm">
                <div className="grid grid-cols-12 gap-2">
                    <div
                        className={`col-span-12 lg:col-span-2 text-black hover:text-black`}
                    >
                        <div className="flex p-1 gap-1 justify-center lg:text-sm border-b-2 border-blue-500">
                            {t('Provider')}
                        </div>
                    </div>
                    {
                        groupedProvider
                            .sort((a, b) => {
                                if(a.sort === null) return 1;
                                if(b.sort === null) return 0;
                                return a.sort - b.sort;
                            })
                            .slice(0, showAllProviders ? groupedProvider.length : 5)
                            .map((p: ProvidersType, index: number) => (
                            <div
                                className={`col-span-6 lg:col-span-2 cursor-pointer ${selectedProvider !== p.provider ? "text-gray-700" : "text-black"} hover:text-black transition duration-300 ease-in-out`}
                                key={`${index}-provider-${p.provider}`}
                                onClick={() => {
                                    setSelectedProvider(p.provider)
                                }}
                            >
                                <div className={`flex bg-gray-200 p-1 rounded-lg gap-1 ${selectedProvider === p.provider && "shadow-inner border border-blue-300"} transition duration-300 ease-in-out`}>
                                    <LazyLoadImage
                                        src={p.logo}
                                        alt={p.name}
                                        className="w-[100%] max-w-[20px] max-h-[20px] rounded-lg"
                                        effect="blur"
                                    />
                                    <p className={`text-sm whitespace-nowrap overflow-hidden text-ellipsis`}>
                                        {
                                            p.name
                                        }
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                    <div
                        className={`col-span-12 lg:col-span-12`}
                    >
                        <div className="flex justify-center">
                            <p className="text-sm cursor-pointer text-blue-500 hover:text-blue-900 transition duration-300 ease-in-out" onClick={() => {
                                setShowAllProviders(!showAllProviders)
                            }}>
                                {
                                    !showAllProviders ? "SHOW ALL PROVIDER" : "LESS THAN PROVIDER"
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-2 mt-2">
                {
                    selectedProvider && games
                        .filter((game: GameType) => game.gameCategory.toLowerCase() === category && selectedProvider === game.provider)
                        .sort((a, b) => {
                            if(a.sort === null) return 1;
                            if(b.sort === null) return 0;
                            return a.sort - b.sort;
                        })
                        .slice((currentPage - 1) * limitPerPage, ((currentPage - 1) * limitPerPage) + limitPerPage)
                        .map((game: GameType, index: number) => (
                        <div
                            className="col-span-4 lg:col-span-2 relative group"
                            style={{ marginBottom: '2rem' }}
                            key={`${index}-game-${category}-${game.provider_provider}-${game.gameName.replace(/ /g, "_").toLowerCase()}`}
                        >
                            <LazyLoadImage
                                className="rounded-lg w-full h-full object-cover"
                                src={game.image_vertical} 
                                effect="blur"
                                wrapperClassName="w-full h-full"
                            />
                            <div className="flex justify-center">
                                <p className="text-md leading-none whitespace-nowrap overflow-hidden text-ellipsis">
                                    {
                                        game.gameName
                                    }
                                </p>
                            </div>
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                                <Button
                                    onClick={() => startGame(game.game_id, game.provider, game.gameCategory)}
                                    isDisable={gettingStartGameUrl}
                                >
                                    {t('Play')}	
                                </Button>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="grid grid-cols-12 gap-2 mt-2">
                <div className="col-span-12 flex justify-center">
                    <Pagination
                        onPageChange={setCurrentPage}
                        currentPage={currentPage}
                        totalPages={
                            Math.max(1, Math.ceil(games.filter((game: GameType) => game.gameCategory.toLowerCase() === category && selectedProvider === game.provider).length / limitPerPage))
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default GamesHome;