import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import "./i18n";
// CSS
import './assets/css/index.css';
import 'react-notifications-component/dist/theme.css';

// COMPONENTS
import App from './App';
import Home from './components/Home/Home';

// CONT
import { AuthContextProvider } from './contexts/AuthContext';
import { LoginContextProvider } from './contexts/LoginContext';
import { GamesContextProvider } from './contexts/GamesContext';
import { WebsiteSettingsContextProvider } from './contexts/WebsiteSetting';
import GamesHome from './components/Games/GamesHome';
import DepositMain from './components/Deposit/DepositMain';
import { BankContextProvider } from './contexts/BankContext';
import MemberDataMain from './components/MemberData/MemberDataMain';
import { SidebarContextProvider } from './contexts/SidebarContext';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/", element: <Home />
            },
            {
                path: "/game/:category", element: <GamesHome />
            },
            {
                path: "/deposit", element: <DepositMain />
            },
            {
                path: "/member/", element: <MemberDataMain />
            },
            {
                path: "/member/:page", element: <MemberDataMain />
            },
            {
                path: "*", element: <Home />
            }
        ]
    }
]);

root.render(
    <SidebarContextProvider>
        <BankContextProvider>
            <WebsiteSettingsContextProvider>
                <AuthContextProvider>
                    <LoginContextProvider>
                        <GamesContextProvider>
                            <React.StrictMode>
                                <RouterProvider router={router} />
                            </React.StrictMode>
                        </GamesContextProvider>
                    </LoginContextProvider>
                </AuthContextProvider>
            </WebsiteSettingsContextProvider>
        </BankContextProvider>
    </SidebarContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
