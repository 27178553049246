import { Outlet } from "react-router-dom";
import Header from "./components/_layouts/Header";
import LoginRegisterModal from "./components/_layouts/Modals/LoginRegisterModal";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import SlideImages from "./components/SlideImages";
import Footer from "./components/_layouts/Footer";
import Sidebar from "./components/_layouts/Sidebar";


const App = () => {
    return (
       <>
            <ReactNotifications />
            <Header />
            <Sidebar />
            <LoginRegisterModal />
            <div className="containe max-w-[1100px] mx-auto p-3">
                <SlideImages />
            </div>
            <Outlet/>
            <Footer />
       </>
    )
}

export default App;