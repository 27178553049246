import HomeOverAllGames from "./Games/HomeOverAllGames";

const Home = () => {
    return (
        <div className="containe max-w-[1100px] mx-auto p-3">
            <HomeOverAllGames />
        </div>
    )
}

export default Home;