import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ApiService } from '../services/api.service';
import Cookies from 'universal-cookie';
import { MemberType } from '../types/Member';

export interface IAuthContextProps {
	accessToken: string | null;
	setAccessToken?(...args: unknown[]): unknown;
	memberData: MemberType | null;
	logout: () => void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);
interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const cookies = new Cookies(null, { path: '/' });
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [memberData, setMemberData] = useState<MemberType | null>(null);

	const setCookiesAccessToken = (access_token: string | null) => {
		if(!access_token)
		{
			cookies.remove('access_token');
			setAccessToken(null);
			return;
		}

		const expires = new Date();
		expires.setDate(expires.getDate() + 30);
		cookies.set('access_token', access_token, { expires });
		setAccessToken(access_token);
	}

	useEffect(() => {
		setCookiesAccessToken(accessToken || cookies.get('access_token'));
	}, [accessToken]);

	useEffect(() => {
		(async () => {
			reloadCheckAccessToken();
		})();
	}, [accessToken]);

	const logout = () => {
		setCookiesAccessToken(null);
	}

	const reloadCheckAccessToken = async () => {
		if (accessToken || cookies.get('access_token'))
		{
			if(!accessToken)
			{
				setCookiesAccessToken(cookies.get('access_token') || null);
			}
			
			try {
				const service = new ApiService();
				const checkAccessToken = await service.checkAccessToken(accessToken || cookies.get('access_token'));
				
				if(checkAccessToken.data.status_code === 200)
				{
					setMemberData(checkAccessToken.data.member_data);
				}
				else
				{
					setCookiesAccessToken(null);
				}
			} catch (err) {
				console.error(err);
			}
		}
	}

	const value = useMemo(
		() => ({
			setAccessToken,
			accessToken,
			memberData,
			logout
		}),
		[accessToken, memberData]
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
