import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import th from "./locales/th.json";

console.log("🔍 i18n resources:", { en, th }); // Debug เช็ค JSON

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      th: { translation: th },
    },
    lng: "th",
    fallbackLng: "th",
    debug: true, // เปิด Debug mode
    interpolation: { escapeValue: false },
  });

export default i18n;
