import { useContext, useEffect, useState } from "react";
import SidebarContext from "../../contexts/SidebarContext";
import { useNavigate } from "react-router-dom";

const menus: {
    label: string,
    path: string
}[] = [
    {
        label: 'หน้าหลัก',
        path: '/'
    },
    {
        label: 'โปรโมชั่น',
        path: '/member/promotions'
    },
    {
        label: 'ฝากเงิน',
        path: '/member/deposit'
    },
    {
        label: 'ถอนเงิน',
        path: '/member/withdraw'
    },
    {
        label: 'ประวัติฝากเงิน',
        path: '/member/history-deposit'
    },
    {
        label: 'ประวัติถอนเงิน',
        path: '/member/history-withdraw'
    }
]

const Sidebar = () => {
    const navigate = useNavigate();
    const { isOpen, setIsOpen } = useContext(SidebarContext);

    const onClose = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isOpen && onClose) {
                onClose();
            }
        };

        // ปิดการ scroll เมื่อ modal เปิด
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.body.style.overflow = ""; // คืนค่า scroll เมื่อ modal ปิด
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, onClose]);

    return (
        <div className={`fixed inset-0 z-50 ${isOpen ? "visible" : "invisible"}`}>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${isOpen ? "opacity-100" : "opacity-0"}`}
                onClick={onClose}
            ></div>
            <div
                className={`fixed top-0 left-0 h-full w-[300px] bg-white shadow-lg transform transition-transform ${isOpen ? "translate-x-0" : "-translate-x-full"}`}
            >
                <div className="flex justify-between p-4 border-b items-center">
                    <img src={`${process.env.REACT_APP_API_URL}/logo/${process.env.REACT_APP_WEBSITE_ID}/logo-horizontal.png?v=${Math.floor(Date.now() / 1000)}`} alt="Logo" style={{ 
                        maxWidth: '150px'
                    }} />
                    <div className="cursor-pointer" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="p-2 text-gray-600">
                    <div className="grid grid-cols-12 gap-0">
                        {
                            menus.map((menu, index) => (
                                <div
                                    key={`sidebar-menu-${index}`}
                                    className="col-span-12 lg:col-span-12"
                                >
                                    <div className={`w-full bg-gray-100 hover:bg-gray-300 p-2 text-md cursor-pointer ${index === 0 && menus.length - 1 !== 0 ? "rounded-t-lg" : (
                                        (index + 1) === menus.length ? "rounded-b-lg" : ""
                                    )} items-center hover:text-blue-700`} onClick={() => {
                                        setIsOpen(false);
                                        navigate(menu.path);
                                    }}>
                                        {menu.label}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;